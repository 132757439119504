@import url(https://fonts.googleapis.com/css?family=Inconsolata);
@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate360 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes computing-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes computing-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes computing-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes computing-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.spinner {
  border-radius: 50px;
  border-style: solid;
  border-bottom-color: rgba(11, 83, 148, 1);
  border-top-color: rgba(11, 83, 148, 1);
  border-left-color: rgba(11, 83, 148, 0.6);
  border-right-color: rgba(11, 83, 148, 0.6);
  text-align: center;
  vertical-align: middle;
  position: relative;
  animation: rotate360 1s linear infinite;
  -webkit-animation: rotate360 1s linear infinite;
  border-width: 5px;
  width: 30px;
  height: 30px;
}

.line-1 {
  position: relative;
  top: 50%;
  width: 15.2em;
  margin: 0 auto;
  border-right: 2px solid rgba(255, 255, 255, 0.75);
  font-size: 18px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
  font-family: "Iconsolata", monospace;
  margin-bottom: 20px;
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 15.2em;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(0, 0, 0, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}

.anim-typewriter {
  animation: typewriter 2s steps(25) 0s 1 normal both,
    blinkTextCursor 500ms steps(25) infinite normal;
}

#landingpage_intro p a.md-button {
  margin: auto auto;
  padding-left: 30px;
  padding-right: 30px;
}

#logotype {
  height: 30px;
  width: 26px;
  vertical-align: middle;
  padding-left: 20px;
  box-sizing: content-box;
}

#landingpage_intro h1::after {
  background-color: black;
  bottom: 0;
  content: "";
  height: 1px;
  left: 50%;
  margin-left: -15px;
  overflow: hidden;
  position: absolute;
  width: 30px;
}

#landingpage_intro h1 {
  padding-bottom: 20px;
}

#landingpage_intro a {
  text-decoration: none;
}

#landingpage_intro p {
  width: 80%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0px;
  font-size: 30px;
  margin-top: 0;
}

#landingpage_intro h1,
#landingpage_intro h3,
#landingpage_intro p {
  text-align: center;
  position: relative;
  color: black;
  margin-bottom: 30px;
}

.intro_v2 h1 {
  font-size: 80px;
  letter-spacing: 4px;
  font-weight: 400;
}

.intro_v2 h1 span {
  font-size: 10px;
  letter-spacing: 0;
}

@media (max-width: 400px) {
  #landingpage_intro p a {
    margin-top: 10px !important;
  }
}

@media (max-width: 600px) {
  .intro_tab_img {
    width: 100%;
  }
  .tab_text_content {
    width: 100%;
    padding-left: 0px;
  }
  .card_grid_wrapper {
    width: 100%;
    margin: 0;
  }
  .logo-footer {
    display: none;
  }
  #timeline-plot {
    display: none;
  }
  .intro_v2 h1 {
    font-size: 45px;
  }
  #landingpage_intro p {
    font-size: 20px;
  }
  #landpage-motto h1 {
    font-size: 30px !important;
  }
  #landpage-motto {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
}

#service_list_wrapper {
  overflow-x: auto;
}

.carousel {
  width: 100%;
  height: 300px;
  margin-bottom: 16px;
}

#suggestion-section {
  background-color: rgb(245, 245, 245);
  z-index: 5;
  color: black;
  text-align: center;
  padding-bottom: 20px;
}

#suggestion-wrapper {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  max-width: 600px;
  position: relative;
  margin: auto auto;
  border-radius: 5px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 10px 0px;
}
